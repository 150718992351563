import ScaleLoader from 'react-spinners/ScaleLoader'

export default function Loading({logo}) {
  const COLOR = "var(--tblr-primary)"
  if ( logo ) {
    return <>
             <img src={process.env.PUBLIC_URL + '/logo text black horizontal.svg'} className="loading-logo" style={{width: '50vw'}}/>
             <ScaleLoader color={COLOR} loading height={100} width={20} aria-label="Loading"/>
           </>
  } else {
    return <ScaleLoader color={COLOR} loading size={300} aria-label="Loading"/>
  }
}
