import { useState } from 'react'

export default function Image(props) {
  const { ratio, src } = props
  const [ loaded, setLoaded ] = useState(false)
  let imgProps = Object.assign({}, {style: {}}, props)

  delete imgProps.ratio

  let placeholder = null
  if ( !loaded ) {
    placeholder = props.placeholder
    if ( placeholder === undefined ) {
      placeholder = <div className={`placeholder ratio ratio-${ratio}`}><div className="placeholder-image"></div></div>
    }

    imgProps.style = { width: '1px', height: '1px', opacity: 0 }
  }

  return <>
           {placeholder}
           <img {...imgProps} onLoad={() => setLoaded(true)}/>
          </>
}
