export default function Ribbon({children, color}) {
  let extraClass = ""
  let style = {}
  if ( typeof color == 'string' && !color.startsWith('#') ) {
    extraClass = `bg-${color}`
  } else if ( typeof color == 'string' ) {
    style.backgroundColor = color
  }
  return (<div style={style} className={`ribbon ${extraClass}`}>{children}</div>)
}
