import Container from 'react-bootstrap/Container'

import {useOutletContext} from 'react-router-dom'
import {useEffect} from 'react'

export default function Profile() {
  const { db, setTitle, userName, plan } = useOutletContext()
  useEffect(() => {
    setTitle("Profile")
  }, [setTitle])
  return (<div className="page-body">
            <Container>
              <div className="card">
                <div className="card-body">
                  <h1>Profile</h1>
                  <dl>
                    <dt>Username</dt>
                    <dd>{userName}</dd>

                    <dt>Plan</dt>
                    <dd>{plan}</dd>

                    <dt>Metro Areas</dt>
                    <dd></dd>

                    <dt>Notifications</dt>
                    <dd></dd>
                  </dl>
                </div>
              </div>
            </Container>
          </div>)
}
