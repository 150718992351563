import * as sqlite from '../wa-sqlite/src/sqlite-api.js'
import * as vfs from '../wa-sqlite/src/VFS.js'

//import { S3Client, GetObjectCommand, HeadObjectCommand } from '@aws-sdk/client-s3';

const CHUNK_SIZE = 4096
let read = 0
let GLOBAL_NAMES = {}

const log = console.log

function RemoteFile(size, name, chunks) {
    this.size = size;
    this.name = name;
    this.chunks = {};
    this.overWritten = {}

    this.handleWrite = function(fileId, buf, ofst) {
        return vfs.SQLITE_IOERR
    }

    this.handleRead = async function(pData, iOffset) {
        try {
            //            let chunk = (iOffset / CHUNK_SIZE) * CHUNK_SIZE
            let end = iOffset + pData.value.length - 1 //((iOffset + pData.value.length + CHUNK_SIZE - 1) / CHUNK_SIZE) * CHUNK_SIZE
            let r = await fetch(this.name, { method: 'GET',
                                             headers: { Range: `bytes=${iOffset}-${end}` }, mode: 'cors' })
            if ( r.ok ) {
                let data = new Int8Array(await r.arrayBuffer())
                pData.value.set(data)
                return vfs.SQLITE_OK
            } else {
                console.error("COULD NOT FETCH", r.status)
                return vfs.SQLITE_IOERR
            }
        } catch (e) {
            console.error("EEE", e)
        }
    }

    return this;
}

//function InMemoryFile(name) {
//    this.name = name;
//    this.size = 0;
//    this.backing = new ArrayBuffer()
//
//    this.handleWrite = function(fileId, buf, ofst) {
//        console.log("Write in-mem", fileId, buf, ofst)
//        let newLength = Math.max(this.backing.byteLength, ofst + buf.value.length)
//        if ( newLength > this.backing.byteLength ) {
//            const nextBuffer = new ArrayBuffer(newLength)
//            new Int8Array(nextBuffer).set(new Int8Array(this.backing), 0)
//            this.backing = nextBuffer
//            this.size = newLength
//        }
//
//        new Int8Array(this.backing).set(buf.value, ofst)
//
//        return vfs.SQLITE_OK
//    }
//
//    this.handleRead = async function (pData, iOffset) {
//        console.log("HANDLE READ", iOffset, pData.value.length, this.backing.byteLength)
//        let end = pData.value.length + iOffset
//        let backing = new Int8Array(this.backing)
//        if ( end > this.backing.byteLength ) {
//            console.log("SHORT READ")
//            pData.value.set(backing.slice(iOffset))
//            pData.value.fill(0, end, pData.value.length)
//            return vfs.SQLITE_IOERR_SHORT_READ
//        } else {
//            pData.value.set(backing.slice(iOffset, end))
//            return vfs.SQLITE_OK
//        }
//    }
//
//    return this;
//}

class Http extends vfs.Base {
    constructor() {
        super()
        this.name = 'http'
        this.files = {}
    }

    getFile(id) {
        return this.files[id]
    }

    resolveName(name) {
        if ( GLOBAL_NAMES.hasOwnProperty(name) ) {
            return GLOBAL_NAMES[name]
        } else {
            return name
        }
    }

    async fetchInfo(name) {
        name = this.resolveName(name)
        console.log("Fetchi", name)
        let r = await fetch(name, { method: 'GET', mode: 'cors', headers: {Range: 'bytes=0-1' } })
        if ( r.ok ) {
            console.log('RANGE', r.headers.get('Content-Range'), r.headers)
            let size = parseInt(r.headers.get('Content-Range').split('/')[1])
            console.log("Got object", size)
            return new RemoteFile(size, name)
        } else if ( r.status == 404 ) {
            return null
        } else {
            throw new Error("Could not fetch", r.status)
        }
    }

    xAccess(name, flags, pResOut) {
        return this.handleAsync(async () => {
            log("ACCESS", name, flags)
            if ( flags & sqlite.SQLITE_ACCESS_READWRITE ) {
                pResOut.set(1)
                return vfs.SQLITE_OK
            }
            let r = await fetch(this.resolveName(name), { method: 'GET', mode: 'cors', headers: {Range: 'bytes=-10'} })
            if ( r.ok ) {
                pResOut.set(1)
                return vfs.SQLITE_OK
            } else if ( r.status == 404 ) {
                pResOut.set(1)
                return vfs.SQLITE_OK
            } else {
                return vfs.SQLITE_IOERR
            }
        })
    }

    xOpen(name, fileId, flags, pOutFlags) {
//        console.log("REQUEST OPEN", name, fileId)
        return this.handleAsync(async () => {
            let info = await this.fetchInfo(name)
            if ( info ) {
                console.log("Open http", fileId, name)
                this.files[fileId] = info
            }
            pOutFlags.set(sqlite.SQLITE_OPEN_READONLY);
            return sqlite.SQLITE_OK
        })
    }

    xClose(fileId) {
        log("CLOSE", fileId)
        return sqlite.SQLITE_OK
    }

    xWrite(fileId, buf, len, ofst) {
        const file = this.files[fileId]
        return file.handleWrite(fileId, buf, len, ofst)
    }

    xDelete() {
        log("DELETE", arguments)
        return vfs.SQLITE_OK
    }

    xTruncate() {
        log("TRUNCATEE", arguments)
        return vfs.SQLITE_IOERR
    }

    xFileSize(fileId, pSize64) {
        log("filesize", fileId)
        log("is", this.getFile(fileId).size)
        pSize64.set(this.getFile(fileId).size)
        return vfs.SQLITE_OK;
    }

    xDeviceCharacteristics(fileId) {
        log('xDeviceCharacteristics');
        return vfs.SQLITE_IOCAP_IMMUTABLE |
            vfs.SQLITE_IOCAP_SEQUENTIAL |
            vfs.SQLITE_IOCAP_SAFE_APPEND |
            vfs.SQLITE_IOCAP_UNDELETABLE_WHEN_OPEN;
    }

    xSectorSize(fileId) {
        log('xSectorSize');
        return CHUNK_SIZE
    }

    xRead(fileId, pData, iOffset) {
        let myRead = read
        return this.handleAsync(async () => {
            read += 1
            console.log("START READ", myRead, fileId, pData, iOffset)
            const file = this.getFile(fileId)
            if (file) {
                return await file.handleRead(pData, iOffset)
            } else {
                return vfs.SQLITE_IOERR
            }
        })
    }

    xLock(fileId, flags) {
        console.log("Lock")
        return vfs.SQLITE_OK
    }

    xUnlock(fileId, flags) {
        console.log("unLock")
        return vfs.SQLITE_OK
    }

    xSync(fileId, flags) {
        console.log("SYNC")
        return vfs.SQLITE_OK
    }
}

let done = false
export default class Database {
  constructor () {
    this.busy = false
    this.queue = []
  }

  async close() {
    await this.sqlite3.close(this.database)
    this.database = null
  }

  async _query(q) {
    let res = []
    this.busy = true
    try {
      await this.sqlite3.exec(this.database, q, (row, columns) => {
        res.push(Object.fromEntries(columns.map((c, i) => [c, row[i]])))
      })
    } finally {
      this._markNonBusy()
    }
    return res
  }

  _markNonBusy() {
    const self = this;
    this.busy = false
    if ( this.queue.length > 0 ) {
      let b = self.queue.shift();
      self._query(b.query).then(b.resolve).catch(b.error)
    }
  }

  query(q) {
    if ( this.busy ) {
      return new Promise((resolve, error) => {
        this.queue.push({query: q, resolve: (r) => { console.log("RESULT", r); resolve(r) }, error})
      })
    } else {
      return this._query(q)
    }
  }

  async detach(d) {
    await this.sqlite3.exec(this.database, `DETACH DATABASE ${d}`, () => {})
  }

  async attach(d, uri) {
    Database.registerFile(d, uri)
    let x = await this.sqlite3.exec(this.database, `ATTACH DATABASE '${d}' AS ${d}`, (r, c) => { console.log("attach result", r, c) })
    console.log("Got in attach ", x)
  }
}

Database.open = async function(file) {
  let self = new Database()
  console.log("Open", file, "in browser")
  const SQLiteESMFactory = (await import ('../wa-sqlite/dist/wa-sqlite-async.mjs')).default
  const module = await SQLiteESMFactory()
  const sqlite3 = sqlite.Factory(module)

  if ( !done ) {
    done = true
    sqlite3.vfs_register(new Http(), true)
  }
  self.sqlite3 = sqlite3

  if ( file != ':memory:' ) {
    self.database = await self.sqlite3.open_v2(file, sqlite.SQLITE_OPEN_READONLY, 'http')
  } else {
    self.database = await self.sqlite3.open_v2(file)
  }
  console.log("Gat database", self.database)
  return self
}

Database.registerFile = function (nm, url) {
    GLOBAL_NAMES[nm] = url
}
