import L from 'leaflet'
import { useRef, useEffect } from 'react'
import { useMap, useMapEvents } from 'react-leaflet/hooks'

import markerIcon2XPng from 'leaflet/dist/images/marker-icon-2x.png'
import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png'

const markerIcon = L.icon({ iconUrl: markerIconPng,
                            iconRetinaUrl: markerIcon2XPng,
                            shadowUrl: markerShadowPng,
		            iconSize:    [25, 41],
		            iconAnchor:  [12, 41],
		            popupAnchor: [1, -34],
		            tooltipAnchor: [16, -28],
		            shadowSize:  [41, 41] })

export default function MarkerGroup({items, onPan, id}) {
  const markers = useRef({})
  const layer = useRef(L.markerClusterGroup())
  const map = useMap()
  useMapEvents({
    moveend: () => {
      if ( onPan )
        onPan(map.getBounds())
    }
  })
  useEffect(() => {
    map.addLayer(layer.current)
  }, map)
  useEffect(() => {
    console.log("REMAKE MARKERS", items.length)
    let curMarkers = {}
    let created = 0, deleted = 0
    items.map((i) => {
      let itemId = id(i)
      if ( !markers.current.hasOwnProperty(itemId) ) {
        let lyr = L.marker([i.latitude, i.longitude], {icon: markerIcon})
        layer.current.addLayer(lyr)
        markers.current[itemId] = lyr
        created += 1
      }
      curMarkers[itemId] = true
    })

    // Now for every marker in markers not in current
    Object.keys(markers.current).map((old) => {
      if ( !curMarkers.hasOwnProperty(old) ) {

        layer.current.removeLayer(markers.current[old])
        delete markers.current[old]
        deleted += 1
      }
    })
    console.log(created, deleted, "CREATED/DELETED")
  }, [items])
}
