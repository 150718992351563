import Loading from './Loading.js'

import * as React from 'react'

//interface Props {
//  unit?: string,
//  unitPosition?: 'left' | 'right'
//  value: any
//}

export default class StatCard extends React.Component {
    render() {
      let { unitPosition, unit, value, loading } = this.props
      if ( loading ) return <Loading/>
        if ( unitPosition === undefined )
            unitPosition = 'right';

        let unitEl = null
        let leftUnitEl = null
        let rightUnitEl = null
        if ( unit ) {
            unitEl = (
                <small>{unit}</small>
            )
        }

        if ( unitPosition == 'right' )
            rightUnitEl = unitEl
        else
            leftUnitEl = unitEl

        return (<>
                  <div className="h1 m-3">
                    {leftUnitEl}
                    {value}
                    {rightUnitEl}
                  </div>
                  <div className="d-flex mb-2">

                </div></>
               )
      /*                     <div>Conversion rate</div>
                    <div className="ms-auto">
                      <span className="text-green d-inline-flex align-items-center lh-1">
                        7%
                      </span>
                      </div>* */
  }
}
