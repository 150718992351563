export function bgClass(s) {
    if ( typeof s == 'string' ) {
        if ( !s.startsWith('#') ) {
            return `bg-${s}`
        }
    }
    return ''
}

export function bgStyle(s) {
    if ( typeof s == 'string' && s.startsWith('#') ) {
        return {backgroundColor: s}
    } else {
        return {}
    }
}
