import Moment from 'react-moment'

import Loading from './Loading';

import { useState, useEffect } from 'react';

function MiniNotification(notice) {
  return <div className="list-group-item">
           <div className="row align-items-center">
             <div className="col-auto"><span className="status-dot status-dot-animated bg-red d-block"></span></div>
             <div className="col text-truncate">
               <a href="#" className="text-body d-block">{notice.address}, {notice.city}, {notice.state_or_province} {notice.zip_or_postal_code}</a>
               <div className="d-block text-muted text-truncate mt-n1">
                 <Moment utc fromNow>{notice.first_notice_time}</Moment>
               </div>
             </div>
             <div className="col-auto">
               <a href="#" className="list-group-item-actions">
                 <svg xmlns="http://www.w3.org/2000/svg" className="icon text-muted" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
               </a>
             </div>
           </div>
         </div>
}

export default function MiniNotifications({db}) {
  let [notifications, setNotifications] = useState(null)
  useEffect(() => {
    if ( db ) {
      (async () => {
        let notices = await db.query('SELECT * FROM homes h JOIN notifications.notifications n WHERE h.property_id=n.property_id AND n.new OR n.`update` ORDER BY n.new DESC, n.`update` DESC, n.first_notice_time DESC LIMIT 10')
        setNotifications(notices)
      })()
    }
  }, [db])

  const mkNotice = (notice) => {
    return <MiniNotification {...notice} key={notice.property_id}/>
  }

  return <div className="list-group list-group-flush list-group-hoverable">
    {notifications === null ? <Loading/> : notifications.map(mkNotice)}
  </div>
}
