import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

export default function OpportunityList({db, query, chunk, component}) {
  const [ results, setResults ] = useState([])
  const [ hasMore, setHasMore ] = useState(true)

  if ( chunk === undefined )
    chunk = 4

  useEffect(() => {
    (async () => {
      let resultsSummary = await db.query(`${query} LIMIT ${chunk}`)
      setResults(resultsSummary)
      if ( resultsSummary.length >= chunk ) {
        setHasMore(true)
      }
    })()
  }, [db, query])

  async function loadMore(page) {
    if ( db === null ) return
    let newItems = await db.query(`${query} LIMIT ${chunk} OFFSET ${chunk * page}`)
    console.log("LOAD", page, "HAS MORE", newItems.length >= chunk)
    setHasMore(newItems.length >= chunk)
    setResults([...results, ...newItems])
  }

  return (
      <InfiniteScroll pageStart={0} loadMore={loadMore} useWindow={true}
        hasMore={hasMore} loader={<div class="placeholder"/>} key={query}>
      {results.map(component)}
      </InfiniteScroll>
  )
}
