import Container from 'react-bootstrap/Container'

import DashboardCard from './components/DashboardCard'
import StatCard from './components/StatCard'
import InfiniteQueryResultList from './components/InfiniteQueryResultList'
import OpportunityNotification from './components/OpportunityNotification'
import MarkerGroup from './components/MarkerGroup'

import Humanize from 'humanize-plus'
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'

import { useOutletContext, useParams } from 'react-router-dom';

import { useState, useEffect, useRef } from 'react';
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'

function mkDetail(n) {
  if ( n.notice_id == 4 ) {
    return `Zoning: ${n.zone_code} (${n.zone_source}) (\$${Humanize.formatNumber(n.priceoversqft_lot, 2)} / sqft)`
  }
}

export default function Dashboard() {
  const { region } = useParams()
  const { db, setTitle } = useOutletContext()
  const [ inventoryCount, setInventoryCount ] = useState(null)
  const [ averagePrice, setAveragePrice ] = useState(null)
  const [ notificationCount, setNotificationCount ] = useState(null)
  const [ homes, setHomes ] = useState([])
  const [ daysOnMarket, setDaysOnMarket ] = useState(null)
  const [ pan, setPan ] = useState(null)

  useEffect(() => {
    setTitle("Overview")
  })

  useEffect(() => {
    (async () => {
      let [ {count, price, days_on_market} ] = await db.query('SELECT COUNT(1) AS count, AVG(price) AS price, AVG(days_on_market) AS days_on_market FROM homes')
      setInventoryCount(count)
      setAveragePrice(price)
      setDaysOnMarket(days_on_market)

      let homes = await db.query('SELECT property_id, latitude, longitude FROM homes h WHERE EXISTS(SELECT 1 FROM notifications n WHERE n.property_id=h.property_id)')
      setHomes(homes)
      setNotificationCount(homes.length)
    })()
  }, db)

  async function updateEvents(b) {
    const southwest = b._southWest;
    const northeast = b._northEast;
    setPan([southwest, northeast])
  }

  let map = (<div className="ratio ratio-4x3 placeholder"><div className="placeholder-image"></div></div>);

  map = (<MapContainer zoom={10} center={[45.523064, -122.676483]} scrollWheelZoom={true} style={{height: '90%', width: '100%', minHeight: '500px'}}>
           <TileLayer
               attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
             />
             <MarkerGroup items={homes} onPan={updateEvents} id={(x) => x.property_id}/>
         </MapContainer>)

  const mkOpportunity = (n) => {
    let badges = []
    if ( n['new'] ) {
      badges.push({name: 'New', color: 'primary'})
    } else if ( n.update ) {
      badges.push({name: 'Update', color: 'secondary'})
    }
    return <OpportunityNotification subject={`${n.address}, ${n.city}, ${n.state_or_province} ${n.zip_or_postal_code}`}
              detail={mkDetail(n)} badges={badges} key={`${n.property_id}-${n.notice_id}`}
              image={`https://deals.realestatepulse.email/image/${region}/${n.property_id}`}
              when={n.first_notice_time}/>
  }

  let noticeQuery = "SELECT * FROM notifications n JOIN homes h ON h.property_id = n.property_id ORDER BY new DESC, `update` DESC, first_notice_time DESC, property_id"
  if ( pan !== null ) {
    const [ southwest, northeast ] = pan
    noticeQuery = `SELECT * FROM notifications n JOIN homes h ON h.property_id = n.property_id WHERE (h.latitude BETWEEN ${southwest.lat} AND ${northeast.lat}) AND (h.longitude BETWEEN ${southwest.lng} AND ${northeast.lng}) ORDER BY new DESC, \`update\` DESC, first_notice_time DESC, property_id`
  }

  return (<div className="page-body">
    <Container>
      <div className="row mb-3 d-flex flex-row">
        <div className="col-md-6 col-lg-7">
          <DashboardCard header="Neighborhood" style={{height: '100%'}}>
            {map}
          </DashboardCard>
        </div>
      <div className="col-lg-5 col-md-6">
          <DashboardCard header="Opportunities" className="mb-3" style={{height: '100%'}}>
            <div className="divide-y mt-3 scroll-y overflow-auto"  style={{maxHeight: "500px"}}>
               <InfiniteQueryResultList db={db} query={noticeQuery}
                 component={mkOpportunity}/>
            </div>
          </DashboardCard>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
          <DashboardCard header="Inventory">
            <StatCard value={inventoryCount} loading={inventoryCount === null}/>
          </DashboardCard>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
          <DashboardCard header="Asking Price">
            <StatCard value={Humanize.formatNumber(averagePrice, 2)} unit='$' unitPosition='left' loading={averagePrice===null}/>
          </DashboardCard>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
          <DashboardCard header="Days On Market">
            <StatCard value={Humanize.formatNumber(daysOnMarket, 0)} loading={daysOnMarket === null}/>
          </DashboardCard>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
          <DashboardCard header="Deals Identified">
           <StatCard value={notificationCount} loading={notificationCount === null}/>
          </DashboardCard>
        </div>
      </div>
    </Container>
          </div>)
}
