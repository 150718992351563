import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

import Select from 'react-select/async';

import { createContext, useContext, cloneElement} from 'react'

import Draggable from 'react-draggable'

const QueryContext = createContext({})
const QueryBuilderContext = createContext({})

function DragDiv(props) {
  let style = {}
  if ( props.style.transform == 'translate(0px,0px)' ) {
    delete props.style.transform
  }
  console.log("GOT PROPS", props)
  return <div {...props}/>
}

function QueryGroup({kind, queries, setQueries, readonly}) {
  const queryKinds = useContext(QueryBuilderContext)
  if ( readonly === undefined )
    readonly = false
  if ( queries === undefined )
    queries = []

  function updateQueryItem(i) {
    return (x) => {
      let nextQueries = [ ...queries ]
      if ( x === undefined ) {
        nextQueries.splice(i, 1)
      } else {
        nextQueries[i] = x
      }
      setQueries(nextQueries)
    }
  }

  let queryEls = queries.map((q, i) => {
    let queryEl = <SubQueryBuilder query={q} setQuery={updateQueryItem(i)}/>
    if ( i > 0 ) {
      queryEl = <><span class="group-operator">{kind}</span>{queryEl}</>
    }
    return queryEl
  })

  function addCondition(template) {
    return (e) => {
      template = Object.assign({id: crypto.randomUUID()}, template)
      setQueries([...queries, template])
    }
  }

  let nextCond = "or"
  if ( kind == 'or' )
    nextCond = "and"

  let addButton = null
  if ( !readonly ) {
    let conditionActions = Object.keys(queryKinds).map((kind) => {
      let k = queryKinds[kind]
      return <Dropdown.Item onClick={addCondition({type: kind})}>{k.humanName}</Dropdown.Item>
    })
    addButton = <Dropdown as={ButtonGroup}>
                  <Button variant="primary" onClick={addCondition({})}>Add</Button>
                  <Dropdown.Toggle split variant="primary"/>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={addCondition({})}>Add new condition...</Dropdown.Item>
                    <Dropdown.Divider/>
                    {conditionActions}
                    <Dropdown.Divider/>
                    <Dropdown.Item onClick={addCondition({type: nextCond, sub: []})}>Add new {nextCond.toUpperCase()} group</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
  }
  return <div className={`query-group query-group-${kind}`}>{queryEls}{addButton}</div>
}

function SubQueryBuilder({info, query, setQuery, readonly}) {
  const queryKinds = useContext(QueryBuilderContext)
  const queryContext = useContext(QueryContext)

  if ( readonly === undefined )
    readonly = false
  function updateQuery(key) {
    return (x) => {
      setQuery(Object.assign({}, query, {[key]: x}))
    }
  }

  let queryEls = []
  if ( query.type == 'and' || query.type == 'or' ) {
    let type = query.type;
    if ( type === undefined )
      type = "and";
    queryEls = <QueryGroup key={query.id} kind={type} queries={query.sub} readonly={readonly}
                           setQueries={updateQuery('sub')}/>
  } else {
    if ( queryKinds.hasOwnProperty(query.type) ) {
      let queryType = queryKinds[query.type]
      if ( queryType.component ) {
        let props = {}
        if ( queryType.hasOwnProperty("mkProps") ) {
          props = queryType.mkProps(query, queryContext)
        }
        queryEls = <queryType.component meta={queryType} query={query} {...props} setQuery={setQuery} key={query.id}/>
      } else
        queryEls = <div className="query-builder-item">Error</div>
    } else {
      queryEls = <ChooseCondition query={query} setQuery={setQuery}></ChooseCondition>
    }
  }
  return queryEls
}

function makeSqlQuery(kinds, q) {
  console.log("MAKE QUERY", q)
  if ( q === undefined ) return "TRUE"
  if ( q.type == 'and' || q.type == 'or' ) {
    return q.sub.map((x, i) => { console.log("GOT SUB", x, i); return "(" + makeSqlQuery(kinds, x) + ")" }).join(" " + q.type + " ")
  } else {
    if ( kinds.hasOwnProperty(q.type) ) {
      if ( kinds[q.type].makeSqlQuery ) {
        return kinds[q.type].makeSqlQuery(q)
      } else {
        return "TRUE"
      }
    } else {
      return "TRUE"
    }
  }
}

export function QueryBuilder(props) {
  let {queryKinds, context, setSqlQuery, query} = props

  function setQuery(q) {
    console.log("SET QUERY", q)
    if ( props.setQuery ) {
      props.setQuery(q)
    }
    if ( setSqlQuery ) {
      setSqlQuery(makeSqlQuery(queryKinds, q))
    }
  }

  if ( Array.isArray(query) )
    query = { type: 'and', sub: query }
  if ( !query.hasOwnProperty('type') ) {
    query = { type: 'and', sub: [] }
  }

  let passedProps = Object.assign({}, props, {setQuery, query})
  if ( queryKinds === undefined ) queryKinds = {}
  return (<QueryBuilderContext.Provider value={queryKinds}><QueryContext.Provider value={context}><SubQueryBuilder {...passedProps}/></QueryContext.Provider></QueryBuilderContext.Provider>)
}

function QueryBuilderItem({type, name, comparisons, defaultComparison, children, query, setQuery}) {
  const kinds = useContext(QueryBuilderContext)
  function selectComparison(h) {
    return () => {
      setQuery(Object.assign({}, query, {comparison: h}))
    }
  }
  function deleteItem() {
    setQuery(undefined)
  }
  let comparisonsEl = null
  if ( comparisons ) {
    let comparison = defaultComparison
    if ( query.comparison ) {
      comparison = query.comparison
    }
    comparisonsEl = (
      <div className="query-builder-comparisons">
        <Dropdown className="d-inline-block">
          <Dropdown.Toggle as='a'>
            {comparisons[comparison].humanName}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {Object.keys(comparisons).map((c) => <Dropdown.Item onClick={selectComparison(c)}>{comparisons[c].humanName}</Dropdown.Item>)}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
  let fields = Object.keys(kinds).map((k) => {
    let t = kinds[k]
    return <Dropdown.Item onClick={() => setQuery({type: k})}>{t.humanName}</Dropdown.Item>
  })
  return <Draggable handle=".query-builder-item-grabber" axis="y" defaultClassNameDragging='dragging'>
           <DragDiv className={`query-builder-item query-builder-condition query-builder-condition-${type} d-flex flex-row`}>
             <div className="query-builder-item-grabber"></div>
             <div className="query-builder-item-container flex-grow-1">
               <div className="query-builder-item-actions">
                 <a href="#" onClick={deleteItem}>x</a>
               </div>
               <div className="query-builder-condition-name flex-grow-1">
                 <Dropdown>
                   <Dropdown.Toggle as="span">
                     {name}
                   </Dropdown.Toggle>
                   <Dropdown.Menu>
                     {fields}
                   </Dropdown.Menu>
                 </Dropdown>
               </div>
               {comparisonsEl}
               {children}
             </div>
           </DragDiv>
         </Draggable>
}

export function DiscreteValue({meta, query, loadOptions, setQuery}) {
  const COMPARISONS = { 'equal':  { humanName: 'is one of' },
                        'not-equal': { humanName: 'is not one of'},
                        'contains': { humanName: 'contains' } }
  const SELECT_STYLES = { menu: styles => ({ ...styles, zIndex: 1000, position: 'absolute', transform: 'translate(0px, 0px)' }) }

  function onChange(value, type) {
    setQuery(Object.assign({}, query, { values: value.map((v) => v.label) }))
  }

  let control =
    <Select isMulti cacheOptions defaultOptions loadOptions={loadOptions} styles={SELECT_STYLES}
            onChange={onChange} allowCreateWhileLoading createOptionPosition="first"
            defaultValue={query.values} isValidNewOption={() => true}
            formatCreateLabel={(e) => e} getNewOptionData={(e) => { return {label: e} }}/>
  if ( query.comparison == 'contains' ) {
    control = <input className="form-control" type="text" placeholder="Text to search for" initialValue={query.search}/>
  }
  return (<QueryBuilderItem type="discrete"
                            name={meta.humanName}
                            query={query} setQuery={setQuery}
                            comparisons={COMPARISONS} defaultComparison="equal">
            {control}
          </QueryBuilderItem>)
}

function ChooseCondition({query, setQuery}) {
  return <QueryBuilderItem type="choose" name="Choose..." query={query} setQuery={setQuery} comparisons={null}/>
}

export function NumericBound({meta, query, setQuery, children, unit}) {
  const COMPARISONS = { 'in-range': {humanName: 'in range'}}
  let unitSuffix = ''
  if ( unit !== undefined ) {
    unitSuffix = ` (${unit})`
  }

  function update(field) {
    return (e) => {
      let nextQuery = Object.assign({}, query, {[field]: parseInt(e.target.value)})
      if ( e.target.value.length == 0 ) {
        delete nextQuery[field]
      }
      setQuery(nextQuery)
    }
  }

  return (<QueryBuilderItem type="discrete"
                            name={meta.humanName}
                            query={query} setQuery={setQuery}
                            comparisons={COMPARISONS} defaultComparison="in-range">
            <div className="d-flex flex-row align-items-center">
              <input type="number" placeholder={`min${unitSuffix}`} className="form-control"
                     initialValue={query.minimum} onChange={update('minimum')}/>&nbsp;-&nbsp;
              <input type="number" placeholder={`max${unitSuffix}`} className="form-control"
                     initialValue={query.maximum} onChange={update('maximum')}/>
            </div>
            {children}
          </QueryBuilderItem>)
}
