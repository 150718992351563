export default function DashboardCard(props) {
  const {header, children, className, scroll, actions} = props
  let elProps = Object.assign({}, props, {className: `card ${className}`})
  let bodyProps = {}
  if ( scroll ) {
    bodyProps = { style: { height: '100%' } }
  }
  return (
      <div {...elProps}>
      <div className="card-body" {...bodyProps}>
          <div className="d-flex flex-row align-items-center mb-2">
            <div className="subheader flex-grow-1">{header}</div>
            {actions}
          </div>
          {children}
        </div>
      </div>
  )
}
