import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import InfiniteScroll from 'react-infinite-scroller'
import { useRef, useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom';
import useResizeObserver from '@react-hook/resize-observer';

import DashboardCard from './components/DashboardCard'
import OpportunityCard from './components/OpportunityCard'
import MarkerGroup from './components/MarkerGroup'
import Loading from './components/Loading'
import { QueryBuilder, DiscreteValue, NumericBound } from './querybuilder/QueryBuilder.js'

import Chart from 'react-apexcharts'

import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'

const HistogramChart = ({column, query}) => {
  const BAR_WIDTH = 5

  const { db } = useOutletContext()
  const chartContainer = useRef(null)
  const [ bucketCount, setBucketCount ] = useState(10)
  const [ chartData, setChartData ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ bounds, setBounds ] = useState([-Math.inf, Math.inf])

  let annotations = []

  if ( query.minimum !== undefined || query.maximum !== undefined ) {
    let[ min, max ] = bounds
    if ( query.minimum !== undefined )
      min = query.minimum
    if ( query.maximum !== undefined )
      max = query.maximum
    annotations.push({x: min, x2: max})
  }

  const CHART_OPTIONS = {
    chart: { id: `histo-${column}`,
             sparkline: { enabled: true } },
    plotOptions: {
      bar: { columnWidth: '100%' }
    },
    annotations: { xaxis: annotations },
    xaxis: { type: 'numeric', tickPlacement: 'between' }
  }
  console.log("annotations", CHART_OPTIONS)

  useResizeObserver(chartContainer, (e) => {
    let buckets = Math.max(10, Math.floor(e.contentRect.width / BAR_WIDTH))
    console.log("USING BUCKETS", buckets)
    setBucketCount(buckets)
  })

  useEffect(() => {
    (async () => {
      let [ { mean, stdev, max, min } ] = await db.query(`SELECT AVG(\`${column}\`) as mean, STDEV(\`${column}\`) as stdev, MAX(\`${column}\`) as max, MIN(\`${column}\`) as min FROM homes`)
      const SIGMA = 2

      setBounds([min, max])
      min = mean - (SIGMA * stdev)
      max = mean + (SIGMA * stdev)

      // Divide max-min into bucketCount buckets
      const spacing = (2 * SIGMA * stdev) / (bucketCount + 1)

      const groupQuery = `SELECT COALESCE(bin, 0) AS bin, COUNT(1) AS count FROM (SELECT (MIN(${bucketCount + 1}, MAX(0, FLOOR((\`${column}\` - ${min})/${spacing})))*${spacing}+${min}) AS bin FROM homes) p GROUP BY p.bin`
      console.log("GOT QUERY", groupQuery)
      let data = await db.query(groupQuery)
      console.log("GOT DATA", data)
      setChartData(data.map((d) => [d.bin, d.count]))
//      setCategories(data.map((d, i) => {
//        return parseFloat(d.bin)
//      }))
    })()
  }, [ bucketCount ])

  const SERIES = [{ name: column, data: chartData}]
  return <div ref={chartContainer}><Chart options={CHART_OPTIONS} series={SERIES} type='line' height={100}/></div>
}

const discreteSelectable = (humanName, colName) => {
  return { humanName,
           component: DiscreteValue,
           makeSqlQuery: (q) => {
             console.log("Make sql query", q)
             let comparison = 'equal'
             if ( q.comparison !== undefined )
               comparison = q.comparison

             if ( q.values && q.values.length > 0 ) {
               const escape = (v) => v.replace('\'', '\\\'')
               if ( comparison == 'not-equal' || comparison == 'equal' ) {
                 let op = 'IN'
                 if ( comparison == 'not-equal' )
                   op = 'NOT IN'
                 let values = q.values.map((v) => '\'' + escape(v) + '\'').join(',')
                 return `\`${colName}\` ${op} (${values})`
               } else {
                 return q.values.map((v) => `\`${colName}\` LIKE '%${escape(v)}%'`).join(' OR ')
               }
             } else {
               return "TRUE"
             }
           },
           mkProps: (query, context) => {
             return {
               loadOptions: async (q) => {
                 let query = q
                 console.log("Query", q)
                 return (await context.db.query(`SELECT DISTINCT \`${colName}\` AS label FROM homes WHERE \`${colName}\` LIKE '%${query}%'`)).map((l) => { return { label: l.label, value: l.label }})
               }
             }
           } }
}

const numericBounds = (humanName, colName, unit) => {
  return { humanName,
           component: NumericBound,
           makeSqlQuery: (q) => {
             if ( q.minimum !== undefined && q.maximum !== undefined ) {
               return `\`${colName}\` BETWEEN ${q.minimum} AND ${q.maximum}`
             } else if ( q.minimum !== undefined ) {
               return `\`${colName}\` >= ${q.minimum}`
             } else if ( q.maximum !== undefined ) {
               return `\`${colName}\` <= ${q.maximum}`
             } else {
               return 'TRUE'
             }
           },
           mkProps: (query, context) => { return { unit } } }
}

const numericBoundWithHistogram = (humanName, colName, ...args) => {
  let spec = numericBounds(humanName, colName, ...args)
  const oldMkProps = spec.mkProps;
  spec.mkProps = (query, context) => {
    let props = oldMkProps(query, context);
    return {
      children: <HistogramChart column={colName} query={query}/>
    };
  }
  return spec;
}

const QUERY_KINDS = {
  zone_source: discreteSelectable("Zoning Source", "zone_source"),
  zone_code: discreteSelectable("Zoning Code", "zone_code"),
  zone_name: discreteSelectable("Zoning Name", "zone_name"),
  zone_type: discreteSelectable("Zoning Type", "zone_type"),

  property_type: discreteSelectable("Property Type", "property_type"),
  sale_type: discreteSelectable("Sale Type", "sale_type"),
  city: discreteSelectable("City", "city"),
  state: discreteSelectable("State", "state_or_province"),
  zip_code: discreteSelectable("Postal code", "zip_or_postal_code"),
  location: discreteSelectable("Location", "location"),

  lot_size: numericBoundWithHistogram("Lot Size (sqft)", "lot_size", "sqft"),
  price: numericBoundWithHistogram("Price", "price", "$"),
  square_feet: numericBoundWithHistogram("Square feet", "square_feet", "sqft"),
  days_on_market: numericBoundWithHistogram("Days on Market", "days_on_market", "days"),
  hoa_per_month: numericBoundWithHistogram("HOA dues (monthly)", "hoaovermonth", "$"),
  beds: numericBounds("Beds", "beds"),
  baths: numericBounds("Baths", "baths"),

  price_per_sqft_lot: numericBoundWithHistogram("Price / Sqft Lot", "priceoversqft_lot")
}

export default function Browse() {
  const { db, setTitle, token } = useOutletContext()
  const [ hasMore, setHasMore ] = useState(true)
  const [ items, setItems ] = useState([])
  const [ homes, setHomes ] = useState([])
  const [ loaded, setLoaded ] = useState(0)
  const [ pan, setPan ] = useState(null)
  const [ condition, setCondition ] = useState('1')
  const [ filterCondition, setFilterCondition ] = useState('1')
  const [ query, setQuery ] = useState({})
  const [ queryName, setQueryName ] = useState(null)
  const results = useRef()
  const scrollRef = useRef(null)

  function mkOpportunity(p) {
    return <OpportunityCard data={p} key={p.property_id}/>
  }

  useEffect(() => { setTitle("Explore") })

  useEffect(() => {
    (async () => {
      let homes = await db.query('SELECT property_id, latitude, longitude FROM homes h')
      setHomes(homes)
    })()
  }, [db])

  function updateQuery(q) {
    console.log("Got q", q)
    setFilterCondition(q)
    setItems([])
    setHasMore(true)
    results.current.pageLoaded = 0;

    (async () => {
      let homes = await db.query(`SELECT property_id, latitude, longitude FROM homes h WHERE ${q}`)
      setHomes(homes)
      console.log("SET HOMES", homes)
    })()
  }

  async function loadMore(page) {
    console.log("GOT LOAD", page, condition)
    if ( db === null ) return

    let count = 10
    let start = (page - 1) * count
    let newItems = []
    let q = `SELECT h.*, (json_group_array(json_object('notice_id', n.notice_id, 'notice_data', JSON(n.notice_result))) FILTER (WHERE n.notice_id is not null)) AS notice FROM homes.homes h LEFT JOIN notifications.notifications n ON n.property_id = h.property_id WHERE ${condition} AND ${filterCondition} GROUP BY h.property_id ORDER BY JSON_ARRAY_LENGTH(notice) DESC, h.property_id LIMIT ${count} OFFSET ${start}`
    console.log("RUN QUERY", q)
    newItems = await db.query(q)
    console.log("NSew items", newItems, "from", q)
    if ( newItems.length < count ) {
      setHasMore(false)
    }
    setLoaded(items.length + newItems.length);
    newItems = newItems.map((n) => Object.assign({}, n, { notice: JSON.parse(n.notice) }))
    setItems([...items, ...newItems])
  }
  async function updateEvents(b) {
    const southwest = b._southWest;
    const northeast = b._northEast;
    setPan([southwest, northeast])
    setCondition(`h.latitude BETWEEN ${southwest.lat} AND ${northeast.lat} AND h.longitude BETWEEN ${southwest.lng} AND ${northeast.lng}`)
    setItems([])
    results.current.pageLoaded = 0;
    setHasMore(true)
    console.log("Condition updated")
  }

  const LOADER = <Loading/>

  async function saveAs(queryName) {
    if ( queryName === null ) {
      queryName = { name: "New Query " + (new Date()).toString() }
    }

    let r = await fetch(`https://deals.realestatepulse.email/queries?token=${encodeURIComponent(token)}`,
                        { method: 'POST', body: JSON.stringify({queryName, query}) })
    r = await r.json()
    setQueryName(r.queryName)
  }

  const actionsDropdown = <Dropdown as={ButtonGroup}>
                            <Button variant="outline-primary" size="sm" onClick={() => saveAs(queryName)}>Save</Button>
                            <Dropdown.Toggle split variant="outline-primary"/>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => saveAs(null)}>Save as new</Dropdown.Item>
                              <Dropdown.Header>Saved</Dropdown.Header>
                            </Dropdown.Menu>
                          </Dropdown>

  let queryNameBox = null
  if ( queryName !== null ) {
    queryNameBox = <input value={queryName.name} onChange={(e) => setQueryName(Object.assign({}, queryName, {name: e.target.value}))} placeholder="Query Name" className="form-control" type="text"/>
  }

  return (
    <div style={{margin: '30px 0', height: '80vh'}} className="overflow-auto container-fluid d-flex flex-row">
      <div className="row d-flex flex-row flex-grow-1">
        <div className="col-xl-2 col-lg-3">
          <DashboardCard header="Filter" scroll style={{height: '100%'}}
                         actions={actionsDropdown}>
            {queryNameBox}
            <QueryBuilder query={query} setQuery={setQuery} setSqlQuery={updateQuery} queryKinds={QUERY_KINDS} context={{db}}/>
          </DashboardCard>
        </div>
        <div className="col-xl-6 col-lg-5">
          <MapContainer zoom={10} center={[45.523064, -122.676483]} scrollWheelZoom={true} style={{height: '100%', width: '100%'}}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerGroup items={homes} onPan={updateEvents} id={(x) => x.property_id}/>
          </MapContainer>
        </div>
        <div className="col-lg-4 d-flex flex-column overflow-hidden" style={{height: '100%'}}>
          <DashboardCard header={`${homes.length} Results`} scroll style={{height: '100%'}}>
            <div style={{overflowY: 'auto', height: '100%'}} ref={scrollRef}>
              <InfiniteScroll ref={results}
                              pageStart={0} loadMore={loadMore} useWindow={false} getScrollParent={() => scrollRef.current}
                              hasMore={hasMore} loader={LOADER}>
                {items.map(mkOpportunity)}
              </InfiniteScroll>
            </div>
          </DashboardCard>
        </div>
      </div>
    </div>)
}

