import * as React from 'react'
import Moment from 'react-moment'

import * as Util from './Util'
import Image from './Image'

//type Badge = string | BadgeSpec;

//interface BadgeSpec {
//  name: string,
//  color?: string
//};
//
//interface Props {
//  badges?: Badge[],
//  subject: string,
//  detail: string,
//  image?: string,
//  when: string
//};

export default class OpportunityNotification extends React.Component {
  render() {
    let badges = null
    if ( this.props.badges && this.props.badges.length > 0 ) {
      badges = this.props.badges.map((b) => {
        let badge: BadgeSpec
        if ( typeof b == 'string' ) {
          badge = { name: b }
        } else {
          badge = b;
        }

        return <span class="badge {Util.bgClass(badge.color)}" style={Util.bgStyle(badge.color)}>{badge.name}</span>
      })
    }
    return (
      <div className="row">
        <div className="col-auto">
          <span className="avatar">
            {this.props.image && <Image src={this.props.image} placeholder={[]}/>}
          </span>
        </div>
        <div className="col-auto align-self-center">
          <h3 class="text-truncate">{this.props.subject}&nbsp;&nbsp;{badges}</h3>
          <div>{this.props.detail}</div>
          <div class="text-muted"><Moment utc fromNow>{this.props.when}</Moment></div>
        </div>
      </div>
    )
  }
}
