import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link, Outlet, useSearchParams, useParams, useNavigate, useOutlet } from 'react-router-dom'

import { useState, useEffect } from 'react'

import Loading from './Loading.js';
import Database from './sqlite'
import MiniNotifications from './MiniNotifications';

async function fetchTokenInfo(token) {
  let r = await fetch(`https://deals.realestatepulse.email/data.json?token=${encodeURIComponent(token)}`, { mode: 'cors' })
  return await r.json()
}

function MyLink(props) {
  let props2 = Object.assign({}, props)
  props2.to = props2.href;
  delete props2.href;
  return <Link {...props2}/>
}

export default function Layout() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [token, setToken] = useState(null)
  const [tokenError, setTokenError] = useState(null)
  const [db, setDatabase] = useState(null)
  const [region, setRegion] = useState('')
  const [title, setTitle] = useState('')
  const [userName, setUserName] = useState("Some Person")
  const params = useParams()
  const navigate = useNavigate()
  const baseUrl = `${params.region}/${params.year}/${params.month}/${params.day}/${params.hour}`

  if ( searchParams.get('token') && token === null ) {
    setToken(searchParams.get('token'))
  }

  useEffect(() => {
    if ( token !== null ) {
      fetchTokenInfo(searchParams.get('token')).then(async (r) => {
        setRegion(r.region.humanName)
        setUserName(r.token.name)
        //      setSearchParams("")
        let db = await Database.open(':memory:')
        for ( var x in r.files ) {
          Database.registerFile(x, r.files[x])
        }
        for ( var x in r.statements ) {
          let rs = await db.query(r.statements[x])
          console.log("RESULT", r.statements[x], rs)
        }
        setDatabase(db)

        if ( r.token.year   != params.year ||
             r.token.month  != params.month ||
             r.token.day    != params.day ||
             r.token.hour   != params.hour ||
             r.token.region != params.region ) {
          navigate(`/${r.token.region}/${r.token.year}/${r.token.month}/${r.token.day}/${r.token.hour}/`)
        }
      }).catch((e) => {
        console.error(e)
        setTokenError(e)
      })
    }
  }, token)

  useEffect(() => {
    if ( token !== null && !searchParams.get('token') ) {
      let nextSearchParams = new URLSearchParams(searchParams)
      nextSearchParams.set('token', token)
      setSearchParams(nextSearchParams)
    }
  }, [token, searchParams.get('token')])

  if ( tokenError ) {
    return `${tokenError}`
  }
  if ( db === null ) {
    return <Container className="d-flex flex-column justify-items-center align-items-center" style={{width: '100vw', height: '100vh'}}><Loading logo/></Container>
  }
  return <>
     <div className="page" suppressHydrationWarning>
              <Navbar bg="dark" expand="md" className="navbar-overlap d-print-none navbar-dark">
                <Container size="xl">
                  <Navbar.Toggle aria-controls="navbar-menu"/>
                  <Navbar.Brand className="navbar-brand-autodark pe-0 pe-md-3 navbar-brand-autodark" href="#home">
                    <img src="https://realestatepulse.email/images/logo/light.svg" width="110" alt="Real Estate Pulse" className="navbar-brand-image"/>
                  </Navbar.Brand>
                  <div className="navbar-nav flex-row order-md-last">
                    <NavDropdown
                      className="dropdown-menu-arrow dropdown-menu-end dropdown-menu-card"
                      title={
                        (<>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
                          <span className="badge bg-red"></span>
                        </>)
                      }>
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">New Notifications</h3>
                          </div>
                          <MiniNotifications db={db}/>
                        </div>
                    </NavDropdown>
                    <NavDropdown
                      title={
                        <>
                          <span className="avatar avatar-sm"></span>
                          <div className="d-none d-xl-block ps-2">
                            <div>{userName}</div>
                            <div className="mt-1 small text-muted">Real Estate Pulse Free</div>
                          </div>
                        </>
                      }>
                      <Link to="/profile" className="dropdown-item">Profile</Link>
                      <a href="#" className="dropdown-item">Feedback</a>
                    </NavDropdown>
                  </div>
                  <Navbar.Collapse id="navbar-menu">
                    <div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
                      <Nav>
                        <Nav.Item>
                          <Nav.Link href={`${baseUrl}/`} as={MyLink}>
                            Opportunities
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link href={`${baseUrl}/browse`} as={MyLink}>
                            Explore
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
              <div className="page-wrapper">
                <div className="page-header d-print-none text-white">
                  <div className="container-xl">
                    <div className="row g-2 align-items-center">
                      <div className="col">
                        <div className="page-pretitle">{region}</div>
                        <h2 className="page-title">{title}</h2>
                      </div>
                      <div className="col-auto ms-auto d-print-none">
                        <div className="btn-list">
                          <span className="d-none d-sm-inline">
                            <a href="#" className="btn btn-dark">
                              Share
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Outlet context={{db, setTitle, userName, plan: 'Real Estate Pulse Free', token}}/>
              </div>
    </div>
    </>
}
