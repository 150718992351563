//import '@/styles/globals.css'
import '@tabler/core/dist/css/tabler.css'
import '@tabler/core/dist/css/demo.css'
import 'leaflet/dist/leaflet.css'


import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'

import { useEffect, useState, useRef } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Link, Route
} from "react-router-dom";

import Layout from './components/Layout.js';
import Dashboard from './Dashboard.js';
import Browse from './Browse.js';
import Profile from './Profile.js';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout/>}>
      <Route path="profile" element={<Profile/>}/>
        <Route path=":region/:year/:month/:day/:hour">
        <Route path="browse" element={<Browse/>}/>
        <Route index element={<Dashboard/>}/>
      </Route>
    </Route>
  ))


function App() {
  useEffect(() => {
    require("@tabler/core/dist/js/tabler.min.js");
  })
  return <RouterProvider router={router}/>
}

export default App;
