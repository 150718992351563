import * as React from 'react'

import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Ribbon from './Ribbon'
import Datagrid from './DataGrid'
import Image from './Image'
import { Link, useParams } from 'react-router-dom'

import Humanize from 'humanize-plus'

const NOTICES = {
  [4]: () => (<span class="badge bg-success">Re-development opportunity</span>)
}

function safediv(a, b) {
  if ( b == 0 ) return 0
  else return a / b
}

export default function OpportunityCard({ribbon, ribbonColor, data}) {
  const { region } = useParams()
  let ribbonEl : React.ReactElement<any, any> | null = null
  if ( ribbon ) {
    ribbonEl = (
      <Ribbon color={ribbonColor}>{ribbon}</Ribbon>
    )
  }
  let notices = data.notice.map(({notice_id, notice_data}) => {
    const Notice = NOTICES[notice_id]
    return <Notice {...notice_data}/>
  })
  return (
    <Card className="d-flex flex-column mb-2">
      <Row className="row-0 flex-fill">
        <Col md={3}>
          <Image src={`https://deals.realestatepulse.email/image/${region}/${data.property_id}`} ratio="1x1"/>
        </Col>
        <Col>
          <Card.Body>
            <Card.Title as="div"><Link to={`property/${data.property_id}`}>{data.address}, {data.city}, {data.state_or_province} {data.zip_or_postal_code}</Link></Card.Title>
            <div class="mb-1 d-flex flex-row">
              {notices}
            </div>
            <Datagrid>
              <Datagrid.Item title="Asking Price">
                ${Humanize.formatNumber(data.price, 2)}
              </Datagrid.Item>
              <Datagrid.Item title="Beds">
                {data.beds}
              </Datagrid.Item>
              <Datagrid.Item title="Baths">
                {data.baths}
              </Datagrid.Item>
              <Datagrid.Item title="Days on Market">
                {data.days_on_market}
              </Datagrid.Item>
              <Datagrid.Item title="Floorspace">
                {Humanize.intComma(data.square_feet)} sqft (${Humanize.formatNumber(safediv(data.price, data.square_feet))}/sqft)
              </Datagrid.Item>
              <Datagrid.Item title="Lot Size">
                {Humanize.intComma(data.lot_size)} sqft (${Humanize.formatNumber(data.priceoversqft_lot, 2)}/sqft)
              </Datagrid.Item>
              <Datagrid.Item title="Zone">
                {data.zone_code} - {data.zone_name}<br/>
                {data.zone_source}
              </Datagrid.Item>
            </Datagrid>
          </Card.Body>
        </Col>
      </Row>
      {ribbonEl}
    </Card>
  )
}
